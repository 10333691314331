import { ApiCall } from "../apiCall";

export async function getAustracQuestions(
    venueId: string,
    shiftDate: string,
    signal?: AbortSignal
) {
    const requestUrl = `/${venueId}/austrac/responses?shift_date=${shiftDate}`;
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "AUSTRAC",
        "list",
        "GET",
        {},
        requestUrl,
        signal
    )
        .then((res) => res.json())
        .then((res) => res.data.austrac_responses);
}
