import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { SafeTab } from "./SafeTab";
import { QuantacoLoader } from "../../Components/QuantacoLoader/QuantacoLoader";
import React from "react";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

export const SafeTabWrapper = () => {
    const { venueId, formattedDate } = useVenueSelection();
    const {
        data: safeLocations,
        isLoading,
        isFetching,
    } = useQuery(
        ["collapsableSafeLocations", venueId, formattedDate],
        ({ signal }) =>
            getLocationsQuery(
                [
                    LocationType.safe,
                    LocationType.float,
                    LocationType.office,
                    LocationType.bank,
                ],
                venueId,
                formattedDate,
                signal
            )
    );
    if (safeLocations === undefined || isFetching || isLoading)
        return <QuantacoLoader size={100} />;

    return <SafeTab safeLocations={safeLocations} />;
};
