import { DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { Menu } from "antd/lib";
import dayjs from "dayjs";
import React, { useState } from "react";
import { fetchWrapper } from "../../API";
import { getLoggedInUser } from "../../ApiV2/Helpers/getLoggedInUser";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { dayjsFormat } from "../../utils/date-utils";
import { SubmitButton } from "../StyledComponents";
import "./downloadJournalDropdown.scss";
import {
    MODAL_GENERIC_CONFIRM,
    MODAL_GENERIC_ERROR,
    MODAL_GENERIC_SUCCESS,
} from "../../Pages/CashupHome/ExcelTable/utils";
import { useContextModal } from "../../hooks/useModal";
import { deleteJournals } from "../../ApiV2/Helpers/deleteJournals";

const DownloadJournalButton = () => {
    const { shiftDate, venueId, venueName, formattedDate } = useVenueSelection();
    const [isLoading, setIsLoading] = useState(false);
    getLoggedInUser;
    const { openModal, closeModal } = useContextModal();

    function deleteJournal() {
        deleteJournals(venueId, formattedDate, formattedDate)
            .then(() =>
                MODAL_GENERIC_SUCCESS(
                    openModal,
                    closeModal,
                    `Journals have been cleared for the ${shiftDate}`
                )
            )
            .catch(() =>
                MODAL_GENERIC_ERROR(
                    openModal,
                    closeModal,
                    undefined,
                    `Failed to delete the journals on the ${shiftDate}`
                )
            );
    }

    async function downloadCSV(diff = false, contentType = "csv") {
        setIsLoading(true);
        const diffParam = diff ? "&adj_journal=true" : "";
        const response = await fetchWrapper(
            `/netsuite/journal?from_date=${dayjs(shiftDate, "DD-MM-YYYY").format(
                dayjsFormat.default.format
            )}&to_date=${dayjs(shiftDate, "DD-MM-YYYY")
                .add(1, "day")
                .format(
                    dayjsFormat.default.format
                )}&venue_id=${venueId}&content_type=${contentType}${diffParam}`,
            "GET"
        );

        if (response.ok) {
            const fileData = await response.blob();
            const blobType = contentType === "csv" ? "text/csv" : "application/zip";
            const blob = new Blob([fileData], { type: blobType });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${venueName.replace(
                / /g,
                "-"
            )}-${shiftDate}.${contentType}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else {
            console.error("Failed to download file:", response.statusText);
        }
        setIsLoading(false);
    }

    const downloadMenu: MenuProps["items"] = [
        {
            key: 1,
            label: (
                <Menu.Item
                    key="1"
                    className="dropdown-item"
                    style={{
                        backgroundColor: "white",
                    }}
                    onClick={(e) => {
                        e.domEvent.stopPropagation();
                        downloadCSV(false, "zip");
                    }}
                >
                    Download All journals
                </Menu.Item>
            ),
        },
        {
            key: 2,
            label: (
                <Menu.Item
                    key="2"
                    style={{ backgroundColor: "white" }}
                    className="dropdown-item"
                    onClick={(e) => {
                        e.domEvent.stopPropagation();
                        downloadCSV(true);
                    }}
                >
                    Download journal
                </Menu.Item>
            ),
        },
        {
            key: 2,
            label: (
                <Menu.Item
                    key="2"
                    style={{ backgroundColor: "white" }}
                    className="dropdown-item"
                    onClick={(e) => {
                        e.domEvent.stopPropagation();
                        // @ts-ignore
                        MODAL_GENERIC_CONFIRM(
                            openModal,
                            closeModal,
                            // @ts-ignore
                            deleteJournal,
                            `Are you sure you want to delete all journals for the date ${shiftDate}? This action cannot be undone.`
                        );
                    }}
                >
                    Delete All Journals
                </Menu.Item>
            ),
        },
    ];

    return (
        <div
            style={{
                position: "relative",
                marginRight: "15px",
                display: "flex",
                width: "80px",
            }}
        >
            <SubmitButton
                icon={<DownloadOutlined />}
                backgroundcolor="#00225D"
                bordcolor="None"
                size="large"
                disabled={isLoading}
                type="primary"
                onClick={() => downloadCSV(true)}
                style={{
                    display: "flex",
                    borderRadius: "10px 0 0px 10px",
                }}
                numberofitems={1}
            ></SubmitButton>
            <Dropdown menu={{ items: downloadMenu }} disabled={isLoading}>
                <SubmitButton
                    icon={<MoreOutlined size={30} />}
                    backgroundcolor="#00225D"
                    bordcolor="None"
                    disabled={isLoading}
                    type="primary"
                    style={{
                        display: "flex",
                        borderRadius: "0px 10px 10px 0px",
                        minHeight: "40px",
                        width: "20px",
                    }}
                    numberofitems={1}
                ></SubmitButton>
            </Dropdown>
        </div>
    );
};
export default DownloadJournalButton;
