import { ApiCall } from "../apiCall";

export async function getPosTotals(
    venueId: string,
    shiftDate: string,
    signal?: AbortSignal
) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "POSTOTALS",
        "list",
        "GET",
        {},
        `?venue_id=${venueId}&shift_date=${shiftDate}`,
        signal
    )
        .then((res) => res.json())
        .then((res) => res.data.pos_totals);
}
