import { ApiCall } from "../apiCall";

export async function getShiftStatus(
    venueId: string,
    shiftDate: string,
    signal?: AbortSignal
) {
    if (venueId)
        return await ApiCall(
            undefined,
            "AUTHENTICATED",
            "SHIFTSTATUS",
            "get",
            "GET",
            {},
            `?venue_id=${venueId}&shift_date=${shiftDate}`,
            signal
        )
            .then((res) => res.json())
            .then((res) => res.data);
}
