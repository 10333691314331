import React, { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./Router/Router";
import "./App.less";
import "./numberInput.css";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { QUANTACO_DARK_BLUE } from "@Constants/theme/constants";
import { ModalProvider } from "hooks/useModal";
import { LoaderProvider } from "hooks/loaderProvider";
import MSALProvider from "./Pages/MSALProvider";
// Extend window object for gtag (Google analytics tag)
//trigger a deployment
declare global {
    interface Window {
        gtag: (...args: any[]) => void;
        dataLayer: Record<string, any>;
    }
}

function App(): ReactElement {
    // @ts-ignore
    return (
        <Provider store={store}>
            <MSALProvider>
                <BrowserRouter>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: QUANTACO_DARK_BLUE,
                            },
                        }}
                    >
                        <ModalProvider>
                            <LoaderProvider>
                                <AppRouter />
                                {/*<TokenErrorModal />*/}
                            </LoaderProvider>
                        </ModalProvider>
                    </ConfigProvider>
                </BrowserRouter>
            </MSALProvider>
        </Provider>
    );
}

export default App;
