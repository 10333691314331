import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, DatePicker, Divider, Select } from "antd";

import dayjs from "dayjs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/en_GB";
import { dayjsFormat, disabledDate } from "../../utils/date-utils";
import TopBannerStats from "./TopBannerStats";
import SubmitApproveSection from "./SubmitApproveSection";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { SelectSection, TopBannerContent } from "../StyledComponents";
import { useQuery } from "react-query";
import { getVenues } from "../../ApiV2/Helpers/getVenues";
import { Venue } from "../../Types/Venue";
import { getShiftStatus } from "../../ApiV2/Helpers/getShiftStatus";
import { QuantacoLoader } from "../../Components/QuantacoLoader/QuantacoLoader";
import { CashupTabs } from "./CashupTabs";
import { usePusher } from "../../hooks/usePusher";
import { Channel } from "pusher-js";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";

export default function CashupHeader() {
    const [token] = React.useState<string | null>(
        localStorage.getItem("access_token")
    );
    const {
        venueId,
        setVenueId,
        shiftDate,
        formattedDate,
        onShiftDateChange,
        setVenueName,
        setShiftStatus,
        setChannelId,
    } = useVenueSelection();
    const { data: venues, isLoading: venuesDataIsLoading } = useQuery(
        ["venuesData", token],
        () => getVenues()
    );

    function onDatePickerChange(date: any, dateString: any) {
        onShiftDateChange(dateString);
    }

    const [balance, setBalance] = useState<{
        cash_increase: any;
        bankable: any;
        total_sales: any;
    }>();

    const { pusher } = usePusher();
    const topBannerSubscriptionRef = useRef<Channel>();
    const {
        data: shiftStatus,
        isLoading: shiftStatusIsLoading,
        refetch,
        isRefetching: shiftStatusIsFetching,
    } = useQuery(
        ["shiftStatus", venueId, formattedDate],
        ({ signal }) => getShiftStatus(venueId, formattedDate, signal),
        {
            enabled: venueId !== null && venueId !== "" && venueId !== undefined,
        }
    );
    useEffect(() => {
        if (shiftStatus) {
            setBalance(shiftStatus.balances);
        }
    }, [shiftStatus]);
    useEffect(() => {
        if (shiftStatus && !shiftStatusIsLoading)
            setChannelId(shiftStatus.channel_id);
    }, [shiftStatus, shiftStatusIsLoading]);

    useEffect(() => {
        if (!pusher) return;
        if (!shiftStatusIsLoading && shiftStatus) {
            topBannerSubscriptionRef.current?.unbind_all();
            topBannerSubscriptionRef.current?.unsubscribe();
            topBannerSubscriptionRef.current = pusher.subscribe(
                shiftStatus.channel_id
            );
            topBannerSubscriptionRef.current.bind("balances", (data: any) => {
                setBalance({
                    cash_increase: data.cash_increase,
                    bankable: data.bankable,
                    total_sales: data.total_sales,
                });
            });
        }
    }, [pusher, venueId, shiftStatusIsLoading, shiftStatus]);
    useEffect(() => {
        if (venues) {
            const venueExists = venues.some(
                (venue: Venue) => venue.venue_id === venueId
            );
            if (!venueExists || venueId === "" || venueId === undefined) {
                //default to the first venue if not found or if venueId is invalid
                setVenueId(venues[0].venue_id);
                setVenueName(venues[0].name);
            }
        }
    }, [venues]);

    useEffect(() => {
        refetch();
    }, [formattedDate]);
    const selectedVenue = useMemo(
        () => (venues ? venues : []).find((v: Venue) => v.venue_id === venueId),
        [venueId, venues]
    );
    useEffect(() => {
        if (shiftStatus !== null && shiftStatus !== undefined) {
            setShiftStatus(shiftStatus.shift_status.status);
        }
    }, [shiftStatus]);

    if (venuesDataIsLoading) {
        return <QuantacoLoader size={150} />;
    }

    return (
        <>
            <TopBannerContent>
                <SelectSection>
                    <div style={{ width: "220px", display: "inline-block" }}>
                        <Select
                            style={{ width: "200px" }}
                            showSearch={true}
                            optionFilterProp="label"
                            value={venueId}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                    .toString()
                                    .toLowerCase()
                                    .localeCompare(
                                        (optionB?.label ?? "")
                                            .toString()
                                            .toLowerCase()
                                    )
                            }
                            onSelect={(e, opt) => {
                                setVenueId(e);
                                //@ts-ignore
                                setVenueName(opt.label);
                                //@ts-ignore
                            }}
                            options={venues.map((venue: Venue) => ({
                                label: venue.name,
                                value: venue.venue_id,
                            }))}
                        />{" "}
                        <Divider
                            type="vertical"
                            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
                        />
                    </div>
                    <div style={{ width: "220px", display: "inline-block" }}>
                        <Button
                            //@ts-ignore
                            type="ghost"
                            disabled={dayjs(shiftDate, "DD-MM-YYYY")
                                .subtract(1, "day")
                                .isBefore(dayjs(selectedVenue?.onboarding_date))}
                            className={`custom-button ${
                                dayjs(shiftDate, "DD-MM-YYYY")
                                    .subtract(1, "day")
                                    .isBefore(dayjs(selectedVenue?.onboarding_date))
                                    ? "disabled"
                                    : ""
                            }`}
                            icon={<LeftOutlined />}
                            onClick={() => {
                                onDatePickerChange(
                                    "",
                                    dayjs(shiftDate, "DD-MM-YYYY")
                                        .subtract(1, "day")
                                        .format("DD-MM-YYYY")
                                        .toString()
                                );
                            }}
                        />
                        <DatePicker
                            onChange={onDatePickerChange}
                            value={
                                shiftDate ? dayjs(shiftDate, "DD-MM-YYYY") : dayjs()
                            }
                            style={{ width: "140px" }}
                            allowClear={false}
                            locale={locale}
                            format={dayjsFormat.dayMonthYearSeparateByDash.format}
                            disabledDate={disabledDate(
                                selectedVenue?.onboarding_date
                            )}
                        />
                        <Button
                            //@ts-ignore
                            type="ghost"
                            icon={<RightOutlined />}
                            onClick={() => {
                                onDatePickerChange(
                                    "",
                                    dayjs(shiftDate, "DD-MM-YYYY")
                                        .add(1, "day")
                                        .format("DD-MM-YYYY")
                                        .toString()
                                );
                            }}
                        ></Button>
                    </div>
                </SelectSection>
                {!shiftStatusIsLoading && !shiftStatusIsFetching ? (
                    <>
                        <TopBannerStats
                            bankable={balance?.bankable || 0}
                            cash_increase={balance?.cash_increase || 0}
                            total_sales={balance?.total_sales || 0}
                            isLoading={false}
                        />
                        <SubmitApproveSection
                            approved_by={shiftStatus?.shift_status.approved_by || ""}
                            submitted_by={
                                shiftStatus?.shift_status.submitted_by || ""
                            }
                            status={shiftStatus?.shift_status.status || "0"}
                            selectedDate={shiftDate}
                            selectedVenueId={venueId}
                        />
                    </>
                ) : (
                    <div
                        style={{
                            height: "51px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CentredSpinner size={"small"} />
                    </div>
                )}
            </TopBannerContent>
            <CashupTabs />
        </>
    );
}
