import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { CashupSubLocationItem } from "../../Redux/StateSlices/GroupData/VenuesAPI";
import { Space } from "antd";
import React, { useState } from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { WageringPanel } from "./WageringPanel";
import { getAccountsQuery } from "../../ApiV2/Helpers/getAccountsQuery";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

const WageringTab = () => {
    const { venueId, formattedDate, shiftStatus, setActivePanel } =
        useVenueSelection();
    const {
        data: wageringLocations,
        isLoading: wageringLocationIsLoading,
        isFetching,
    } = useQuery(
        ["collapsableWageringLocations", venueId, formattedDate],
        ({ signal }) =>
            getLocationsQuery(
                [
                    LocationType.wagering,
                    LocationType.keno,
                    LocationType.tab,
                    LocationType.office,
                ],
                venueId,
                formattedDate,
                signal
            )
    );
    const { data: accounts, isLoading: accountsIsLoading } = useQuery(
        ["accounts", venueId],
        () => getAccountsQuery(venueId)
    );

    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();

    if (wageringLocationIsLoading || accountsIsLoading || isFetching) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "50px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"large"}
            />
        );
    }
    //Need to updated to use locations api

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {wageringLocations
                .filter(
                    (wL: ExtendedLocationItemWithChildren) =>
                        wL.location_type === LocationType.wagering
                )[0]
                .sub_locations.map(
                    (subLocation: CashupSubLocationItem, mainIndex: number) => {
                        const panelId = `${subLocation.location_id}-${formattedDate}-${mainIndex}`;
                        return (
                            <CollapsibleTable
                                name={subLocation.name}
                                panelId={panelId}
                                key={panelId}
                            >
                                <WageringPanel
                                    locations={
                                        wageringLocations.filter(
                                            (wL: ExtendedLocationItemWithChildren) =>
                                                wL.location_type ===
                                                LocationType.wagering
                                        )[0]
                                    }
                                    hierarchicalLocations={[
                                        ...wageringLocations.filter(
                                            (wL: ExtendedLocationItemWithChildren) =>
                                                wL.location_type ===
                                                LocationType.office
                                        ),
                                        ...wageringLocations
                                            .filter(
                                                (
                                                    wL: ExtendedLocationItemWithChildren
                                                ) =>
                                                    wL.location_type ===
                                                    LocationType.wagering
                                            )
                                            .slice(0, 1), // this will ensure you only take the first element
                                    ]}
                                    subLocation={subLocation}
                                    key={subLocation.name}
                                    name={subLocation.name}
                                    accountsData={accounts}
                                    cashupIds={
                                        wageringLocations.filter(
                                            (wL: ExtendedLocationItemWithChildren) =>
                                                wL.location_type ===
                                                LocationType.wagering
                                        )[0].cashup_ids
                                    }
                                    onClickHandler={() => {
                                        setCurrenTableInFocus?.(panelId);
                                        setActivePanel(panelId);
                                    }}
                                    isInFocus={panelId === currentTableInFocus}
                                    submitted={shiftStatus !== "UNSUBMITTED"}
                                />
                            </CollapsibleTable>
                        );
                    }
                )}
        </Space>
    );
};
export default WageringTab;
